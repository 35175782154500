import React from 'react'
import { MDBCol, MDBCard, MDBCardBody, MDBCardText } from 'mdbreact'
import { GatsbyImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/pro-solid-svg-icons'
import CustomLink from './CustomLink'

class Card extends React.Component {
  render() {
    const colHeight = this.props.height != null ? this.props.height : 'auto'
    return (
      <MDBCol lg={this.props.collg} md={this.props.colmd} className="d-flex pb-3" >
        <MDBCard className="flex-fill">
          {this.props.link ? (
            <CustomLink link={this.props.link}> {this.props.image && ( <GatsbyImage top image={this.props.image} alt={this.props.alt} className="rounded-top" /> )} </CustomLink>
          ) : (
            <>
              {this.props.image && (
                <GatsbyImage top image={this.props.image} alt={this.props.alt} className="rounded-top" />
              )}
            </>
          )}

          <MDBCardBody className="card-blog">
            <div style={{ maxHeight: colHeight, minHeight: colHeight }}>
              <h3 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-medium mt-2 pb-3"> {this.props.title} </h3>

              {this.props.link ? (
                <CustomLink link={this.props.link} className="effect-noline"> {this.props.subtitle && ( <h4 className="font-w-400 text-card-small ellipsis lines-3"> {this.props.subtitle} </h4> )} </CustomLink>
              ) : (
                <>
                  {this.props.subtitle && (
                    <h4 className="font-w-400 text-card-small ellipsis"> {this.props.subtitle} </h4>
                  )}
                </>
              )}
            </div>

            <hr />

            {this.props.link ? (
              <MDBCardText className={`font-w-400 mt-2 mb-2 ` + this.props.descriptionClass ? this.props.descriptionClass : 'text-medium'}>
                  <FontAwesomeIcon icon={faClock} className="mr-3 ico-color" />
                  <CustomLink link={this.props.link} className="effect">
                    {this.props.readtime}
                  </CustomLink>
              </MDBCardText>
            ) : (
              <MDBCardText className="font-w-400 text-medium mt-2 mb-2"></MDBCardText>
            )}
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    )
  }
}
export default Card