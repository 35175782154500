import React from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import FadeInWhenVisible from './fadeInWhenVisible'
import FormMarketo from './formMarketo'

class cta extends React.Component {
  render() {
    return (
      <section className="bg-blue-green-gradient">
        <FadeInWhenVisible>
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <h2 className="font-alt font-w-700 text-white title-xs-medium title-large"> {this.props.title} </h2>
                <p className="mt-4 font-w-400 text-white text-sm-large text-large-cta"> {this.props.subtitle} </p>
              </MDBCol>
            </MDBRow>
            <FormMarketo formId="1668" />
          </MDBContainer>
        </FadeInWhenVisible>
      </section>
    )
  }
}
export default cta