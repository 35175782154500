import React from 'react'
import { MDBMask, MDBView, MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessageMiddle } from '@fortawesome/pro-regular-svg-icons'
import CustomLink from './CustomLink'

class HeroBlogPage extends React.Component {
  render() {
    const bgImage = convertToBgImage(this.props.image)
    return (
      <BackgroundImage
        Tag="section"
        className={this.props.class}
        backgroundColor={`rgba(228, 237, 243, 0.7)`}
        {...bgImage}
        alt={this.props.alt}
      >
        <MDBView>
          <MDBMask className={ this.props.gradientClass != null ? this.props.gradientClass : 'mask d-flex justify-content-center gradient' }>
            <MDBContainer className="h-100">
              <MDBRow className="align-items-center h-100">
                <MDBCol>
                  {this.props.ctaslug && 
                    <CustomLink link={this.props.ctaslug} className="btn btn-mdb-color mr-0 mt-5 mt-lg-2" >
                      <FontAwesomeIcon icon={faMessageMiddle} className="mr-2" />{' '}
                      {this.props.ctatext ? this.props.ctatext : 'Schedule a consultation'}
                    </CustomLink>
                  }
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBMask>
        </MDBView>
      </BackgroundImage>
    )
  }
}
export default HeroBlogPage