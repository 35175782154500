import React from 'react'
import { graphql, navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { targetingEnabled } from '../utils/helpersCookies.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { faCalendarDays, faUser } from '@fortawesome/pro-solid-svg-icons'
import { MDBRow, MDBCol, MDBContainer, MDBBtn } from 'mdbreact'
import { StickyShareButtons } from 'sharethis-reactjs'
import Layout from '../components/layout'
import Hero from '../components/heroBlogpage'
import SEO from '../components/seo'
import FadeInWhenVisible from '../components/fadeInWhenVisible'
import CTA from '../components/subscribeCta'
import Card from '../components/cardRelatedBlog'
import CookiesParseHtml from "../components/cookiesParseHtml"

const BlogTemplate = ({ data }) => {
  const post = data.markdownRemark
  const cta = data.cta
  const heroImage = data.heroImage
  const siteUrl = data.site.siteMetadata.siteUrl
  const pageUrl = siteUrl + post.fields.slug
  const isTargetingEnabled = targetingEnabled()

  return (
    <div id="sub-page">
      <Layout>
        <Hero
          class="intro-50"
          image={heroImage?.childImageSharp?.gatsbyImageData}
          alt="blurred lights behind a globe of the world"
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}
          type="blog"
        />

        <div className="d-none d-sm-block">
          <StickyShareButtons
            config={{
              alignment: 'right', // alignment of buttons (left, right)
              color: 'social', // set the color of buttons (social, white)
              enabled: true, // show/hide buttons (true, false)
              font_size: 16, // font size for the buttons
              hide_desktop: false, // hide buttons on desktop (true, false)
              labels: 'null', // button labels (cta, counts, null)
              language: 'en', // which language to use (see LANGUAGES)
              networks: [ 'linkedin', 'facebook', 'twitter', 'whatsapp', 'email', ], // which networks to include (see SHARING NETWORKS)
              padding: 12, // padding within buttons (INTEGER)
              show_mobile: false, // show/hide the buttons on mobile (true, false)
              show_toggle: false, // show/hide the toggle buttons (true, false)
              size: 48, // the size of each button (INTEGER)
              top: 85, // offset in pixels from the top of the page
              // OPTIONAL PARAMETERS//
              url: pageUrl, // (defaults to current url)
              description: post.frontmatter.description, // (defaults to og:description or twitter:description)
              title: post.frontmatter.title, // (defaults to og:title or twitter:title)
            }}
          />
        </div>


        <section className="bg-white pb-0">
          <MDBContainer>
            <MDBRow>
              <MDBCol lg="9" className="my-auto text-left" >
                <h1 className="font-alt font-w-700 title-xs-extra-large title-extra-large-1 pb-3" dangerouslySetInnerHTML={{ __html: post.frontmatter.title }} />
              </MDBCol>
            </MDBRow>
            
            <MDBRow className="justify-content-left font-alt font-w-400 text-gray-light text-medium letter-spacing-1 mt-3 mb-0">
              <MDBCol lg="auto" xs="12"> <FontAwesomeIcon icon={faCalendarDays} className="mr-2" />{post.frontmatter.date}</MDBCol>
            
              {post.frontmatter.author && (
                <MDBCol lg="auto" xs="12"> <FontAwesomeIcon icon={faUser} className="mr-2" />{post.frontmatter.author}</MDBCol>
              )}                
              
              {/* {post.frontmatter.readtime && (
                <MDBCol lg="auto" xs="12"> <FontAwesomeIcon icon={faClock} className="mr-2" />{post.frontmatter.readtime} read </MDBCol>
              )} */}
            </MDBRow>
          </MDBContainer>
        </section>

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              {typeof window !== 'undefined' && console.log(isTargetingEnabled)}
              <MDBCol lg="9">
                {post.frontmatter.openingpara && (
                  <MDBRow>
                    <MDBCol>
                      <div className="font-w-400 divlink text-opening-para"> {post.frontmatter.openingpara} </div>
                    </MDBCol>
                  </MDBRow>
                )}

                <MDBRow className="d-none d-md-block">
                  <MDBCol>
                    {(!post.frontmatter.videourl && post.frontmatter.image) && (
                      <div>
                        <GatsbyImage image={post.frontmatter.image.childImageSharp.gatsbyImageData} objectFit="cover" alt={post.frontmatter.alttext} />
                        {post.frontmatter.caption && 
                          <div className="figure pt-3">{post.frontmatter.caption}</div>
                        }
                      </div>
                    )}
                  </MDBCol>
                </MDBRow>      

                {post.frontmatter.videourl && (
                  <MDBCol>
                    <div className="embed-responsive embed-responsive-16by9">
                      <CookiesParseHtml html={`<div><iframe className="embed-responsive-item rounded border" src="${post.frontmatter.videourl}?rel=0&modestbranding=1"></iframe></div>`} />
                    </div>
                  </MDBCol>
                )}  

                  <MDBRow>
                    <MDBCol>
                      <div className="mt-lg-5 mt-0 richtext divlink blog-content" dangerouslySetInnerHTML={{ __html: post.html }} />
                      {(typeof window !== 'undefined' && window.history.length > 1) &&
                        <p className="pt-lg-4 mt-md-4 text-large">
                          <MDBBtn className="nav-link btn btn-mdb-color" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} className="mr-1" />Back</MDBBtn>
                        </p>
                      }
                    </MDBCol>
                  </MDBRow>
              </MDBCol>

              {isTargetingEnabled && 
                <MDBCol lg="3" md="12">
                  <div className="RTP_RCMD2" data-rtp-template-id="template2"></div>
                </MDBCol>
              }
            </MDBRow>
          </MDBContainer>
        </section>

        
        <CTA
          title={cta.frontmatter.title}
          subtitle={cta.frontmatter.subtitle}                  
        />

        {(post.frontmatter.helpfulresources && post.frontmatter.helpfulresources?.helpfulres?.length > 0) && (
          <section className="bg-white-grey-gradient">
            <FadeInWhenVisible>
              <MDBContainer>
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                  {post.frontmatter.helpfulresources.title}
                </h2>
                {post.frontmatter.helpfulresources.helpfulres && (
                  <MDBRow className="pt-5">
                    <>
                      {post.frontmatter.helpfulresources.helpfulres.map((helpfulres, index) => (
                        <Card
                          key={index}
                          collg="4"
                          colmd="6"
                          height="12rem"
                          title={helpfulres.title}
                          subtitle={helpfulres.subtitle}
                          readtime={helpfulres.readtime}
                          image={helpfulres.image.childImageSharp.gatsbyImageData}
                          alt={helpfulres.alttext}
                          placement={helpfulres.placement}
                          link={ helpfulres.link ? helpfulres.link : helpfulres.document.publicURL }
                          titleclass="title-small"
                          descriptionClass="text-card-small py-2"
                        />
                      ))}
                    </>
                  </MDBRow>
                )}
              </MDBContainer>
            </FadeInWhenVisible>
          </section>
        )}
      </Layout>
    </div>
  )
}
export default BlogTemplate

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "blog" } }
    ) {
      fields {
        slug 
      }
      html
      frontmatter {
        meta {
          title
          description 
        }
        template
        title
        date(formatString: "MMMM DD, YYYY")
        author
        description
        openingpara
        videourl
        image {
          childImageSharp {
            gatsbyImageData(quality: 90, layout: CONSTRAINED) 
          }
        }
        alttext
        ctatext
        ctaslug
        imageFeatured {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        caption                          
        helpfulresources {
          title
          helpfulres {
            title
            subtitle
            placement
            link
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90) 
              }
            }
            document {
              publicURL
            }
          }
        }
      }
    }
    cta: markdownRemark(
      frontmatter: { name: { eq: "blog-cta" } }
    ) {
      frontmatter {
        title
        subtitle
        linktext
        }
    } 
    heroImage: file(name: { eq: "blog-hero-image" }) {
      childImageSharp {
        gatsbyImageData(quality: 90) 
      }
    }   
  }
`